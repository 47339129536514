.logo {
  display: block;
  margin: 0;
}

.logo__img {
  width: 38px;
  height: 38px;
  background-image: url("../../images/logo.svg");
  background-repeat: no-repeat;
}
