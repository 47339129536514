.portfolio {
  padding: 0 70px 125px;
}

.portfolio__title {
  margin: 0 0 50px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #8b8b8b;
}

.portfolio__items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.portfolio__item {
  padding: 0 0 19px;
  border-bottom: 1px solid #424242;
}

.portfolio__item:last-child {
  padding: 0;
  border-bottom: 0;
}

.portfolio__link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 30px;
  line-height: 50px;
  font-weight: 400;
  letter-spacing: -0.04em;
}

.portfolio__icon {
  margin-left: auto;
  width: 18px;
  height: 17px;
  background-image: url("../../images/portfolio-link.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (max-width: 984px) {
  .portfolio {
    padding: 0 50px 90px;
  }

  .portfolio__link {
    font-size: 28px;
  }

  .portfolio__icon {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 568px) {
  .portfolio {
    padding: 0 14px 70px;
  }

  .portfolio__title {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .portfolio__items {
    gap: 19px;
  }

  .portfolio__link {
    font-size: 18px;
    line-height: 28px;
  }

  .portfolio__icon {
    width: 11px;
    height: 11px;
  }
}
