.page {
  background-color: #202020;
}

.page__content {
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;
  color: #fff;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.page__link {
  text-decoration: none;
  transition: opacity 0.3s linear;
}

.page__link:hover {
  opacity: 0.7;
}

.page__button {
  text-decoration: none;
  transition: opacity 0.3s linear;
  cursor: pointer;
}

.page__button:hover {
  opacity: 0.7;
}

.form__message {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.form__message_type_error {
  color: #ee3465;
}

.form__message_type_success {
  color: #3ddc84;
}
