.header-authorization {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 40px;
  width: 100%;
  max-width: 396px;
}

.header-authorization__title {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
}

@media screen and (max-width: 568px) {
  .header-authorization {
    align-items: center;
    gap: 50px;
  }
}
