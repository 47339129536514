.promo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #073042;
  padding: 70px 75px;
  gap: 20px;
}

.promo__description {
  display: flex;
  flex-direction: column;
}

.promo__title {
  max-width: 640px;
  margin: 0;
  font-size: 50px;
  line-height: 58px;
  font-weight: 400;
  margin-bottom: 36px;
}

.promo__subtitle {
  max-width: 310px;
  margin: 0 0 146px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.promo__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 36px;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 3px;
}

.promo__img {
  width: 310px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 984px) {
  .promo {
    flex-direction: column-reverse;
    align-items: center;
    padding: 140px 10px 76px;
    gap: 48px;
  }

  .promo__description {
    align-items: center;
  }

  .promo__title {
    max-width: 680px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 22px;
  }

  .promo__subtitle {
    max-width: 260px;
    text-align: center;
    margin-bottom: 92px;
  }
}

@media screen and (max-width: 568px) {
  .promo {
    padding-top: 70px;
    padding-bottom: 30px;
    gap: 44px;
  }

  .promo__title {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.04em;
    margin-bottom: 24px;
  }

  .promo__subtitle {
    margin-bottom: 80px;
  }

  .promo__img {
    width: 210px;
  }
}
