.techs {
  padding: 100px 70px;
  background-color: #272727;
}

.techs__title {
  margin: 0 0 90px;
  padding: 0 0 23px;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: -0.04em;
  border-bottom: 1px solid #dadada;
}

.techs__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.techs__description-title {
  max-width: 600px;
  margin: 0 0 26px;
  font-size: 50px;
  line-height: 58px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__description-paragraph {
  max-width: 460px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 0 0 100px;
}

.techs__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.techs__item {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.04em;
  min-height: 60px;
  background-color: #303030;
  border-radius: 10px;
}

@media screen and (max-width: 984px) {
  .techs {
    padding: 90px 50px;
  }

  .techs__title {
    margin-bottom: 80px;
  }

  .techs__description-title {
    margin-bottom: 22px;
  }

  .techs__description-paragraph {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 83px;
  }

  .techs__item {
    width: 84px;
    min-height: 57px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 568px) {
  .techs {
    padding: 70px 18px;
  }

  .techs__title {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 28px;
    margin-bottom: 60px;
  }

  .techs__description-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .techs__description-paragraph {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 50px;
  }

  .techs__list {
    max-width: 178px;
    justify-content: flex-start;
  }
}
