.profile {
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile__title {
  margin: 0 0 123px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  text-align: center;
}

.profile__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 410px;
  margin: 0 0 16px;
}

.profile__form-label {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #424242;
  padding-bottom: 16px;
  /*   margin-bottom: 16px; */
}

.profile__form-label:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.profile__form-label-text {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400px;
  /*   color: #fff; */
}

.profile__form-input {
  flex-grow: 1;
  background-color: inherit;
  border: none;
  outline: none;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400px;
  color: #fff;
  text-align: right;
}

.profile__form-input::placeholder {
  color: #fff;
}

.profile__form-submit-button {
  margin-top: 220px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400px;
  color: #fff;
  background-color: transparent;
  border: none;
}

.profile__form-submit-button_disabled {
  color: #8b8b8b;
  cursor: auto;
  opacity: 0.7;
}

.profile__form-error {
  /*   padding-top: 6px; */
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #ee3465;
  min-height: 18px;
  opacity: 0;
  visibility: hidden;
}

.profile__form-error_visible {
  opacity: 1;
  visibility: visible;
}

.profile__button-logout {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: #ee3465;
  border: none;
}

@media screen and (max-width: 984px) {
  .profile {
    padding-top: 236px;
  }

  .profile__title {
    margin-bottom: 96px;
  }

  .profile__form-submit-button {
    margin-top: 210px;
  }
}

@media screen and (max-width: 568px) {
  .profile {
    padding-top: 70px;
  }

  .profile__title {
    margin-bottom: 80px;
  }

  .profile__form {
    max-width: 260px;
  }

  .profile__form-submit-button {
    margin-top: 379px;
    font-size: 12px;
    line-height: 15px;
  }

  .profile__button-logout {
    font-size: 12px;
    line-height: 15px;
  }
}
