.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found__code {
  margin: 0 0 5px;
  font-size: 140px;
  line-height: 169px;
  font-weight: 400;
  text-align: center;
}

.not-found__title {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
}

.not-found__link {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  color: #4285f4;
  margin-top: 184px;
  background-color: inherit;
  border: none;
  padding: 0;
}

@media screen and (max-width: 568px) {
  .not-found__code {
    font-size: 80px;
    line-height: 97px;
    margin-bottom: 10px;
  }

  .not-found__title {
    font-size: 12px;
    line-height: 15px;
  }

  .not-found__link {
    margin-top: 284px;
    font-size: 12px;
    line-height: 15px;
  }
}
