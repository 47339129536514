.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 74px;
  padding-left: 70px;
  padding-right: 70px;
}

.header_type_main {
  background-color: #073042;
}

.header__container {
  display: flex;
  justify-content: space-between;
}

.header__container_type_movies {
  margin-left: 44px;
  width: 100%;
}

.header__menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header__link {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.header__link_type_registration {
  color: #fff;
}

.header__link_type_login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 32px;
  background-color: #3ddc84;
  color: #000;
  border-radius: 3px;
}

.header__burger-button {
  min-width: 44px;
  height: 44px;
  padding: 0;
  border: none;
  display: none;
  background-color: inherit;
  background-image: url("../../images/burger-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 984px) {
  .header {
    padding-right: 30px;
    padding-left: 30px;
  }

  .header__burger-button {
    display: block;
  }
}

@media screen and (max-width: 568px) {
  .header {
    padding-right: 14px;
    padding-left: 14px;
  }

  .header__menu {
    gap: 14px;
  }

  .header__link {
    font-size: 10px;
    line-height: 16px;
  }

  .header__link_type_login {
    width: 54px;
    height: 26px;
  }

  .header__burger-button {
    min-width: 40px;
    height: 43px;
  }
}
