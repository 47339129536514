.movies__nothing-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
  color: #ee3465;
}
