.footer {
  padding: 79px 70px 0;
}

.footer__title {
  margin: 0;
  padding: 0 0 20px;
  border-bottom: 1px solid #8b8b8b;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #a0a0a0;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.footer__items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.footer__copyright {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}

.footer__link {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 984px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 568px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer__title {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 21px;
  }

  .footer__container {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 30px;
  }

  .footer__copyright {
    font-size: 12px;
    line-height: 15px;
    color: #8b8b8b;
  }

  .footer__items {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 30px;
  }

  .footer__link {
    font-size: 12px;
    line-height: 15px;
  }
}
