.burger-menu {
  width: 100%;
  max-width: 520px;
  min-height: 100vh;
  position: absolute;
  top: 0;
  padding: 22px 22px 90px;
  box-sizing: border-box;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202020;
}

.burger-menu_active {
  right: 0;
  visibility: visible;
}

.burger-menu__button-close {
  align-self: flex-end;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: url("../../images/burger-close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 105px;
}

.burger-menu__nav {
  margin-bottom: auto;
}

.burger-menu__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin-bottom: auto;
}

.burger-menu__item {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  padding-bottom: 4px;
  color: #fff;
}

.burger-menu__item_active {
  border-bottom: 2px solid #fff;
}

@media screen and (max-width: 568px) {
  .burger-menu {
    padding: 13px 13px 46px;
  }
}
