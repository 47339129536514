.about-project {
  padding: 110px 70px;
}

.about-project__title {
  margin: 0 0 70px;
  padding: 0 0 23px;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: -0.04em;
  border-bottom: 1px solid black;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.about-project__description {
  display: flex;
  gap: 40px;
}

.about-project__description-subtitle {
  max-width: 550px;
  margin: 0 0 26px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.04em;
}

.about-project__description-paragraph {
  max-width: 550px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.about-project__timeline {
  display: flex;
  margin-top: 110px;
}

.about-project__stage {
  display: flex;
  flex-direction: column;
}

.about-project__stage_type_backend {
  width: 20%;
}

.about-project__stage_type_frontend {
  width: 80%;
}

.about-project__stage-text {
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
}

.about-project__stage-text_type_backend {
  color: #000;
  background-color: #2be080;
}

.about-project__stage-text_type_frontend {
  background-color: #303030;
}

.about-project__stage-caption {
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  color: #a0a0a0;
}

@media screen and (max-width: 984px) {
  .about-project {
    padding: 90px 50px;
  }

  .about-project__description {
    gap: 30px;
  }

  .about-project__description-subtitle {
    margin-bottom: 22px;
  }

  .about-project__description-paragraph {
    font-size: 12px;
    line-height: 18px;
  }

  .about-project__timeline {
    margin-top: 93px;
  }

  .about-project__stage_type_backend {
    width: 21%;
  }

  .about-project__stage_type_frontend {
    width: 79%;
  }
}

@media screen and (max-width: 568px) {
  .about-project {
    padding: 70px 18px;
  }

  .about-project__title {
    margin-bottom: 60px;
    padding-bottom: 28px;
  }

  .about-project__description {
    flex-direction: column;
    gap: 56px;
  }

  .about-project__description-subtitle {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .about-project__description-paragraph {
    font-size: 11px;
    line-height: 16px;
  }

  .about-project__timeline {
    margin-top: 60px;
  }

  .about-project__stage-text {
    margin-bottom: 10px;
  }

  .about-project__stage_type_backend {
    width: 35%;
  }

  .about-project__stage_type_frontend {
    width: 65%;
  }

  .about-project__stage-text {
    min-height: 35px;
    font-size: 14px;
    font-size: 11px;
    line-height: 13px;
  }

  .about-project__stage-caption {
    font-size: 11px;
    line-height: 13px;
  }
}
