.account-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 32px;
  border: 2px solid #aaa;
  border-radius: 50px;
}

.account-link__link {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.account-link__icon {
  width: 12px;
  height: 14px;
  background-image: url("../../images/account.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (max-width: 984px) {
  .account-link_hidden {
    display: none;
  }
}
