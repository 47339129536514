.movies-cards {
  padding: 70px 70px 80px;
}

.movies-cards__list {
  padding: 0;
  margin: 0 0 50px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  gap: 38px 20px;
}

.movies-cards__list_space-evenly {
  justify-content: space-evenly; 
}

.movies-cards__button-more {
  display: none;
  width: 100%;
  height: 36px;
  padding: 0;
  border: none;
  border-radius: 6px;
  background-color: #2f2f2f;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
}

.movies-cards__button-more_active {
  display: block;
}

@media screen and (max-width: 990px) {
  .movies-cards {
    padding-right: 30px;
    padding-left: 30px;
  }

  .movies-cards__list {
    grid-template-columns: repeat(auto-fit, 339px);
    justify-content: space-evenly;
    gap: 30px;
  }
}

@media screen and (max-width: 568px) {
  .movies-cards {
    padding: 40px 10px 79px;
  }

  .movies-cards__list {
    grid-template-columns: repeat(auto-fit, 300px);
    gap: 34px;
  }
}
