.movies-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.movies-card__img {
  width: 100%;
  height: 151px;
  object-fit: cover;
  object-position: center;
}

.movies-card__inner {
  min-height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 10px 0 6px;
  border-bottom: 1px solid #424242;
}

.movies-card__title {
  max-width: 226px;
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}

.movies-card__button {
  background-image: url("../../images/movies-like-button.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  border: none;
  background-color: inherit;
}

.movies-card__button-delete {
  background-image: url("../../images/movies-delete-button.svg");
  opacity: 0;
}

.movies-card__button-delete:hover {
  opacity: 1;
}

.movies-card__button-like {
  background-image: url("../../images/movies-like-button-active.svg");
}

.movies-card__duration {
  margin: 0;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  color: #8b8b8b;
}

@media screen and (max-width: 990px) {
  .movies-card__img {
    height: 190px;
  }

  .movies-card__button-delete {
    opacity: 1;
  }
}

@media screen and (max-width: 568px) {
  .movies-card__img {
    height: 168px;
  }

  .movies-card__title {
    font-size: 12px;
    line-height: 15px;
  }

  .movies-card__duration {
    font-size: 10px;
    line-height: 12px;
  }
}
