.register {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 396px;
  margin: 40px 0 16px;
}

.register__form-label {
  display: flex;
  flex-direction: column;
}

.register__form-label-text {
  margin: 0;
  color: #8b8b8b;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.register__form-input {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  padding: 5px 0 10px;
  background-color: inherit;
  border: none;
  outline: none;
  color: #fff;
  border-bottom: 1px solid #424242;
}

.register__form-input_filed {
  color: #ee3465;
}

.register__form-input:focus {
  border-bottom: 1px solid #4285f4;
}

.register__form-error {
  padding-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #ee3465;
  min-height: 18px;
  opacity: 0;
  visibility: hidden;
}

.register__form-error_visible {
  opacity: 1;
  visibility: visible;
}

.register__form-submit-button {
  width: 100%;
  height: 45px;
  padding: 0;
  border: none;
  border-radius: 3px;
  background: #4285f4;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 128px;
}

.register__form-submit-button_disabled {
  opacity: 0.7;
  cursor: default;
}

.register__form-caption {
  margin: 0;
  color: #8b8b8b;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
}

.register__button-logout {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: #ee3465;
  border: none;
}

.register__link-signin {
  color: #4285f4;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  margin-left: 6px;
}

@media screen and (max-width: 984px) {
  .register {
    padding-top: 232px;
  }
}

@media screen and (max-width: 568px) {
  .register {
    padding-top: 56px;
  }

  .register__form {
    max-width: 260px;
    margin: 80px 0 14px;
  }

  .register__form-input {
    font-weight: 400;
  }

  .register__form-submit-button {
    font-size: 12px;
    line-height: 15px;
    margin-top: 216px;
  }

  .register__form-caption {
    margin: 0;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 15px;
  }

  .register__link-signin {
    font-size: 12px;
    line-height: 15px;
    margin-left: 7px;
  }
}
