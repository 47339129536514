.navigation {
  display: flex;
  align-items: center;
  gap: 16px;
}

.navigation__link {
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
}

.navigation__link_active {
  font-weight: 500;
}

@media screen and (max-width: 984px) {
  .navigation {
    display: none;
  }
}
