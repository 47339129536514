.search-form {
  padding: 70px 70px 47px;
}

.search-form__form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-form__input {
  background-color: inherit;
  outline: none;
  border: none;
  padding: 0 0 13px;
  border-bottom: 1px solid #424242;
  color: #fff;
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
}

.search-form__error {
  min-height: 28px;
  padding-top: 5px;
  color: #ee3465;
  font-size: 12px;
  line-height: 15px;
  opacity: 0;
  visibility: hidden;
}

.search-form__error_visible {
  opacity: 1;
  visibility: visible;
}

.search-form__input:focus {
  border-bottom: 1px solid #fff;
}

.search-form__button-submit {
  position: absolute;
  right: 0;
  width: 34px;
  height: 34px;
  background: #4285f4;
  border-radius: 48%;
  background-image: url("../../images/search-film.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  padding: 0;
}

@media screen and (max-width: 984px) {
  .search-form {
    padding: 80px 30px 60px;
  }

  .search-form__input {
    margin-bottom: 29px;
  }
}

@media screen and (max-width: 568px) {
  .search-form {
    padding: 90px 14px 50px;
  }

  .search-form__input {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 40px;
    padding-bottom: 15px;
  }

  .search-form__button-submit {
    top: -9px;
  }
}
