.filter-checkbox {
  display: flex;
  align-items: center;
  gap: 14px;
}

.filter-checkbox__container {
  display: flex;
  position: relative;
  align-items: center;
  width: 36px;
  height: 20px;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.filter-checkbox__toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.filter-checkbox__tumbler {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #a0a0a0;
  transition: transform 0.3s ease-in-out;
}

.filter-checkbox__toggle:checked + .filter-checkbox__tumbler {
  transform: translateX(18px);
  background-color: #3ddc84;
}

.filter-checkbox__text {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
}

@media screen and (max-width: 984px) {
  .filter-checkbox {
    flex-direction: row-reverse;
    gap: 16px;
  }
}

@media screen and (max-width: 568px) {
  .filter-checkbox {
    justify-content: center;
    gap: 13px;
  }

  .filter-checkbox__text {
    font-size: 11px;
    line-height: 18px;
  }
}
